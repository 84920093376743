<template>
  <Shop v-if="isActive" :id="item.reference.shop.id" @inquiry="inquiry">
    <template #summary="{ prev }">
      <Summary
        :categories="categories"
        @update:categories="$ev => $emit('update:categories', $ev)"
        @inquiry="inquiry"
        @next="$emit('next')"
        @prev="prev"
      />
    </template>
    <template #duedates="{ className, next }">
      <Categories
        :contract="value"
        @navigate="() => next(true, { navigateTo: 'summary' })"
        :class="className"
        @inquiry="inquiry"
      />
    </template>
    <template #actions>
      <ButtonComponent
        :label="`${$tc('new')} ${$tc('inquiry').toLowerCase()}`"
        :icon="['fal', 'comment']"
        @click="inquiry"
      />
    </template>
  </Shop>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import Bus from '@/bus'

import Shop from '@kvass/shop'

import Categories from '@/components/Shop/Categories'
import Summary from '../Summary'
const meta = {
  order: 1,
  id: 'shop',
  label: i18n.tc('addonShop'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
    isActive: Boolean,
    categories: Array,
  },
  computed: {
    ...mapState('Contract', ['item']),
  },
  methods: {
    inquiry(payload) {
      Bus.emit('task:show', payload)
    },
  },
  components: {
    Shop,
    Categories,
    Summary,
  },
}
</script>

<style lang="scss">
.shop {
  min-height: 100vh;

  &-signer-intro {
    padding: 2rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include respond-below('phone') {
      padding: 0 1rem;
      gap: 2rem;
    }

    &__action {
      @include respond-above('phone') {
        display: none;
      }
    }

    &__categories {
      .shop-categories__wrapper {
        @include respond-above('phone') {
          max-height: calc(100vh - 420px);
          overscroll-behavior: contain;
          overflow-y: auto;
          overflow-x: auto;
        }

        th {
          background-color: white;
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }

  .shop-intro {
    .shop-footer {
      @include respond-below('phone') {
        border-bottom: none;
      }

      .elder-button--default {
        color: white;
        background-color: var(--primary);
      }
    }
  }
}
</style>
