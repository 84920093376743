<template>
  <div class="shop-category-deadline">
    {{ value }}
  </div>
</template>

<script>
import { Moment } from '@/filters'

export default {
  props: {
    item: Object,
  },
  computed: {
    value() {
      if (!this.item.expiresAt) return '-'
      return Moment(this.item.expiresAt, 'll')
    },
  },
}
</script>

<style lang="scss">
.shop-category-deadline {
  line-height: 1;
  white-space: nowrap;
}
</style>
