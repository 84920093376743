<template>
  <div v-if="item" class="shop-category-status">
    <div class="shop-category-status__dot" :style="{ '--color': `var(--status-color-${item.status})` }"></div>
    <div class="shop-category-status__content">
      <div class="shop-category-status__label">{{ label }}</div>
      <div class="shop-category-status__categories">{{ item.categories.join(', ') }}</div>
    </div>
  </div>
</template>

<script>
import { Moment } from '@/filters'

export default {
  props: {
    item: Object,
  },
  computed: {
    labels() {
      return {
        draft: this.$t('shopBuyerStatusDraft'),
        signable: this.$t('shopBuyerStatusUnsigned'),
        signed: this.$t('shopContractStatusSigned'),
        expired: this.$t('shopContractStatusExpired'),
        upcoming: `${this.$t('signableFrom')} ${Moment(this.item.signableFrom, 'll')}`,
      }
    },
    label() {
      return this.labels[this.item.status]
    },
  },
}
</script>

<style lang="scss">
.shop-category-status {
  --status-color-signable: var(--warning);
  --status-color-upcoming: var(--neutral);
  --status-color-signed: var(--success);
  --status-color-expired: var(--error);
  --status-color-draft: var(--grey);

  display: flex;
  align-items: baseline;
  gap: 0.75rem;

  &__dot {
    $size: 10px;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: var(--color);
    position: relative;
    top: -1px;

    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__label {
    white-space: nowrap;
    line-height: 1;
  }

  &__categories {
    opacity: 0.7;
    font-size: 0.8rem;
    line-height: 1.42;

    max-width: 60ch;
  }
}
</style>
