<template>
  <Loader
    :value="promises.fetchOne"
    theme="default"
    class="shop-sign"
    :icon="['fal', 'spinner-third']"
    :message="$t('loading', { resource: $tc('addonShop').toLowerCase() }) + '...'"
  >
    <StepperComponent v-if="item.id" :value="steps" ref="stepper" state-handler="sessionStorage" :state-key="stateKey">
      <template #breadcrumb="{ item, index }">
        <button type="button" disabled class="elder-stepper__breadcrumb-button">
          <span class="elder-stepper__breadcrumb-icon">
            {{ index + 1 }}
          </span>
          <span>{{ item.label }}</span>
        </button>
      </template>
    </StepperComponent>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Moment } from '@/filters'
import { loadLanguageAsync } from '@/i18n'

import { StepperComponent } from 'vue-elder-stepper'
import Steps from './Steps'

export default {
  props: {
    id: String,
    accessToken: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      categories: [],
      stateKey: new Date().getTime().toString(),
    }
  },
  computed: {
    ...mapState('Contract', ['item', 'promises']),
    steps() {
      let props = {
        value: this.item,
        categories: this.categories,
      }

      return Steps().map(raw => {
        const { icon, ...s } = raw
        s.props = {
          id: raw.id,
          ...(s.props || {}),
          ...props,
          icon,
        }
        s.listeners = {
          ...(s.listeners || {}),
          'update:categories': v => (this.categories = v),
        }
        return s
      })
    },
  },
  methods: {
    ...mapActions('Contract', {
      fetch(dispatch) {
        const notFound = () => this.$router.replace({ name: 'notfound' })

        return dispatch('fetch', [{ id: this.id, accessToken: this.accessToken }, { query: 'ShopContract' }])
          .then(() => {
            if (!this.item.id) return notFound()
            return loadLanguageAsync(this.$path('reference.project.metadata.language', this.item))
          })
          .catch(notFound)
      },
    }),
  },
  metaInfo() {
    return {
      title: this.item.id
        ? `${this.$tc('shopShort')} | ${this.item.reference.name} - ${this.item.reference.project.name}`
        : this.$t('loading', { resource: this.$t('shopShort').toLowerCase() }) + '...',
    }
  },
  filters: {
    Moment,
  },
  components: {
    StepperComponent,
  },
}
</script>

<style lang="scss">
.shop-sign {
  // background-color: $light-grey;
  min-height: 100vh;

  .elder-stepper {
    min-height: inherit;
    display: flex;
    flex-direction: column;

    &__component {
      flex-grow: 1;
      margin-top: 0;
    }
  }

  .elder-stepper__breadcrumbs {
    display: none;
  }

  &__expires-at {
    svg {
      margin-right: 0.25rem;
    }
  }

  &__metadata {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    margin: 2rem auto 0;
  }
}
</style>
