<template>
  <CheckboxComponent
    :state="item"
    :value="value"
    @input="$ev => $emit('input', $ev)"
    :compare="compare"
    :disabled="item.status !== 'signable'"
    class="shop-category-sign-checkbox"
  >
    <template #default> </template>

    <template #icon>
      <Icon v-if="checkboxIcon" :icon="checkboxIcon" />
    </template>
  </CheckboxComponent>
</template>

<script>
import { CheckboxComponent } from 'vue-elder-checkbox'

export default {
  props: {
    value: [Array, Boolean],
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    checkboxIcon() {
      if (this.item.status === 'signed') return ['fas', 'check']
      if (this.item.status === 'expired') return ['fas', 'exclamation']
      if (!this.item.isSignable) return ['far', 'clock']

      if (this.value instanceof Array && this.compare()) return ['fas', 'check']
      return undefined
    },
  },
  methods: {
    compare() {
      return this.value.some(i => (this.item.items || []).includes(i))
    },
  },
  components: {
    CheckboxComponent,
  },
}
</script>

<style lang="scss">
.shop-category-sign-checkbox.elder-checkbox {
  .elder-checkbox__label {
    display: none;
  }
  $size: 1.5rem;

  .elder-checkbox__box {
    height: $size !important;
    margin-right: 0;
  }

  svg {
    color: white;
    font-size: calc(#{$size}/ 2);
  }

  &[disabled='disabled'] .elder-checkbox__box {
    background-color: css-darken('grey', 30%);
  }
}
</style>
