<template>
  <div class="shop-categories">
    <div class="shop-categories__table" :style="{ '--columns': columns.map(({ size = 'auto' }) => size).join(' ') }">
      <div class="shop-categories__table-row shop-categories__table-header">
        <div class="shop-categories__table-cell" v-for="{ label, id } in columns" :key="id">
          {{ label }}
        </div>
      </div>
      <div
        class="shop-categories__table-row"
        v-for="(item, index) in items"
        :key="index"
        @click.stop="() => onClick(item)"
      >
        <div
          class="shop-categories__table-cell"
          v-for="{ component, props = {}, listeners = {}, id } in columns"
          :key="id"
        >
          <component :is="component" v-bind="{ ...props, item }" v-on="listeners" />
        </div>
      </div>
    </div>

    <Alert
      v-if="expiredItems.length"
      class="shop-categories__expired-info"
      icon-placement="left"
      theme="error"
      :icon="['far', 'exclamation-circle']"
    >
      {{ expiredErrorContent }} —
      <a href="#" @click.prevent="$emit('inquiry')">{{ $t('contactSeller').toLowerCase() }}</a
      >.
    </Alert>
  </div>
</template>

<script>
import moment from 'moment'
import { getCategoryState } from '../../components/Shop/utils'
import { ConditionFilter } from '@kvass/shop/src/utils'

import { AlertComponent as Alert } from 'vue-elder-alert'
import CategorySignCheckbox from './CategorySignCheckbox'
import CategoryStatus from './CategoryStatus'
import CategoryDeadline from './CategoryDeadline'

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    columns() {
      const checkbox = {
        id: 'select',
        label: this.$t('select'),
        component: CategorySignCheckbox,
        props: { value: this.value },
      }

      const value = [
        { id: 'status', label: this.$t('status'), component: CategoryStatus, size: '1fr' },
        { id: 'deadline', label: this.$t('deadline'), component: CategoryDeadline },
      ]

      if (this.selectable) value.splice(0, 0, checkbox)

      return value
    },
    expiredItems() {
      return this.items.filter(({ status }) => status === 'expired')
    },
    expiredErrorContent() {
      return this.$tc('oneOrMoreDeadlinesExpired')
    },
    shop() {
      return this.contract.reference.shop
    },
    items() {
      let value = []

      const partials = this.contract.data.partials || []

      if (!partials.length) {
        const { expiresAt, signableFrom, status, isSignable } = getCategoryState(
          this.shop.categories[0].id,
          this.contract,
        )
        return [
          {
            categories: this.shop.categories.map(({ label }) => label),
            items: this.shop.categories.map(({ id }) => id),
            expiresAt,
            signableFrom,
            isSignable,
            status,
          },
        ]
      }

      const items = this.shop.categories
        .filter(category => ConditionFilter(category, this.shop))
        .map(category => getCategoryState(category.id, this.contract))

      for (const partial of items) {
        if (!partial.status) continue

        const target = value.find(({ status, expiresAt }) => {
          const isSameDay =
            (!expiresAt && !partial.expiresAt) || moment(expiresAt).isSame(moment(partial.expiresAt), 'day')
          return isSameDay && status === partial.status
        })

        if (target) {
          target.items.push(partial.id)
          target.categories.push(partial.label)
          continue
        }

        value.push({
          items: [partial.id],
          categories: [partial.label],
          expiresAt: partial.expiresAt,
          signableFrom: partial.signableFrom,
          isSignable: partial.isSignable,
          status: partial.status,
        })
      }

      return value.sort((a, b) => {
        if (a.status === 'draft' && a.status !== b.status) return 1
        if (b.status === 'draft' && a.status !== b.status) return -1

        if (a.expiresAt && b.expiresAt) return a.expiresAt.getTime() - b.expiresAt.getTime()
        if (!a.expiresAt && b.expiresAt) return 1
        if (!b.expiresAt && a.expiresAt) return -1

        if (a.status === 'upcoming' && a.status !== b.status) return 1
        if (b.status === 'upcoming' && a.status !== b.status) return -1

        if (a.status === 'upcoming' && a.status === b.status) return a.signableFrom.getTime() - b.signableFrom.getTime()

        return 0
      })
    },
  },
  methods: {
    onClick(item) {
      if (this.$listeners.navigate) return this.$emit('navigate')

      if (item.status !== 'signable') return
      let result = this.value
      let remove = !this.value.some(i => (item.items || []).includes(i))

      if (!remove) result = result.filter(i => !item.items.includes(i))
      else result = [...result, ...item.items]

      this.$emit('input', result)
    },
  },
  components: {
    Alert,
  },
  mounted() {
    // this.$emit(
    //   'input',
    //   this.items
    //     .filter(({ status }) => status === 'signable')
    //     .map(({ items }) => items)
    //     .flat(),
    // )
  },
}
</script>

<style lang="scss">
.shop-categories {
  $states: (
    signable: 'warning',
    upcoming: 'neutral',
    signed: 'success',
    expired: 'error',
    draft: 'grey',
  );

  --shop-categories-border-color: var(--border-color);

  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &__table {
    display: grid;
    grid-template-columns: var(--columns);

    max-width: 100vw;
    overflow: auto;

    &-header > div {
      padding-top: 0;
      border-bottom: 1px solid var(--shop-categories-border-color);
    }

    &-row {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;

      &:nth-child(2) > div {
        padding-top: 1.2rem;
      }

      &:not(:first-child):is(:hover, :focus-within) {
        cursor: pointer;

        background: linear-gradient(
          to left,
          transparent 0%,
          rgba(black, 0.02) 20%,
          rgba(black, 0.02) 80%,
          transparent 100%
        );
      }
    }

    &-cell {
      padding: 0.8rem 1.5rem 0.8rem 0;
    }
  }

  &__expired-info {
    a {
      color: inherit;
    }

    .alert__content {
      &:before {
        border-radius: var(--border-radius);
      }
    }
  }

  .elder-checkbox {
    pointer-events: none;
  }
}
</style>
