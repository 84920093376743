<template>
  <Loader :value="promises.mutate" theme="default" :message="$t('creatingContracts') + '...'">
    <Card theme="border" class="shop-signer-signing" :class="{ 'shop-signer-signing--active': current }">
      <template #header>
        <h1>{{ $t('shopSigning') }}</h1>
      </template>
      <template #default>
        <Loader
          :value="promise"
          theme="default"
          :message="$t('loading', { resource: $t('shopContract').toLowerCase() })"
        >
          <iframe v-if="current" :src="current.signUrl" frameborder="0"></iframe>
          <div v-else class="row center wrap g-1">
            <ApplicantCard v-for="(item, index) in signers" :value="item" theme="border" :key="index">
              <template #footer>
                <ButtonComponent
                  :label="$t(item.signUrl ? 'sign' : 'signed')"
                  :theme="item.signUrl ? 'primary' : 'success'"
                  :icon="item.signUrl ? ['fal', 'angle-right'] : 'check-circle'"
                  @click="startSigning(item)"
                />
              </template>
            </ApplicantCard>
          </div>
        </Loader>
      </template>
    </Card>
  </Loader>
</template>

<script>
import { i18n } from '@/i18n'
import { mapActions, mapState } from 'vuex'

import Card from '@/components/Card'
import ApplicantCard from '@/components/Applicant'
import { Clone } from '@/utils'
import { Initials } from '@/filters'

const meta = {
  order: 3,
  id: 'signing',
  label: i18n.tc('shopSigning'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    categories: Array,
    labels: Object,
    validator: Object,
    isActive: Boolean,
  },
  watch: {
    isActive: {
      handler(val) {
        if (!val) return
        this.prepare()
      },
      immediate: true,
    },
    signers: {
      handler(val) {
        if (val && val.length === 1) return this.startSigning(val[0])
      },
      immediate: true,
    },
    allSigned: {
      handler(val) {
        if (!val || !this.isActive) return
        this.$emit('update:isValid', true)
        this.$emit('completed')
        return this.$emit('next')
      },
      immediate: true,
    },
  },
  data() {
    return {
      promise: null,
      current: null,
    }
  },
  computed: {
    ...mapState('Contract', ['promises']),
    signers() {
      let res = []
      if (!this.value) return []

      res.push(this.value.data.applicant)
      if (this.$path('value.data.hasCoApplicant')) res.push(this.value.data.coApplicant)

      return res
    },
    allSigned() {
      if (!this.signers.length) return false

      return this.signers.every(s => !s?.signUrl)
    },
  },
  methods: {
    ...mapActions('Contract', {
      prepare(dispatch) {
        return dispatch('mutate', [
          { id: this.value.id, categories: this.categories },
          { mutation: 'ShopContractPrepareSigning', strategy: 'merge', transformPayload: false },
        ])
      },
    }),
    startSigning(signer) {
      if (!signer?.signUrl) return
      this.current = signer
    },
    onSignSuccess(data) {
      this.$set(this.current, 'signUrl', null)
      this.current = null
    },
    onSignCanceled(data) {
      this.current = null
    },
    onSignError(data) {
      this.current = null
      console.log(data)
    },
    messageHandler(event) {
      if (!['idfy.io', 'signicat.io', 'signicat.com'].some(e => event.origin.endsWith(e))) return
      let data, type, payload

      try {
        data = JSON.parse(event.data)
        type = data.type
        payload = data.payload
      } catch (err) {}

      const handlers = {
        sign_success: 'onSignSuccess',
        sign_canceled: 'onSignCanceled',
        sign_error: 'onSignError',
      }

      if (!handlers[type]) return
      return this[handlers[type]](payload)
    },
  },
  created() {
    window.addEventListener('message', this.messageHandler, false)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler)
  },
  filters: {
    Initials,
  },
  components: {
    Card,
    ApplicantCard,
  },
}
</script>

<style lang="scss">
.shop-signer-signing {
  border: none;

  &--active {
    .card__content {
      padding: 0;
    }
  }

  iframe {
    width: 100%;
    height: 800px;
  }

  .card__header {
    min-height: 125px;
  }

  h1 {
    margin: 0;
  }
}
</style>
