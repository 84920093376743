<template>
  <div class="shop-signer-summary">
    <h3>{{ $t('selectCategoriesToSign') }}:</h3>

    <Categories v-model="signRefs" :contract="item" selectable @inquiry="$emit('inquiry')" />

    <div class="shop-signer-summary__actions">
      <ButtonComponent
        v-if="master.isSignable || hasPartials"
        :label="$t('sign')"
        :disabled="!isValid || isLoading"
        theme="primary"
        @click="$emit('next')"
        :icon="['fas', 'badge-check']"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import { ConditionFilter } from '@kvass/shop/src/utils'
import Categories from '@/components/Shop/Categories'

export default {
  props: {
    categories: Array,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('Contract', {
      item: state => state.item,
      shop: state => state.item.reference.shop,
    }),
    partials() {
      return (this.item.data.partials || []).filter(p => {
        let category = this.shop.categories.find(c => c.id === p.reference)
        return ConditionFilter(category, this.shop)
      })
    },
    hasPartials() {
      return this.partials.length && this.partials.some(({ expiresAt, signableFrom }) => expiresAt || signableFrom)
    },
    master() {
      const isExpired = this.item.expiresAt && moment().isSameOrAfter(moment(this.item.expiresAt))

      const isSignable =
        this.item.status === 'unsigned' &&
        !isExpired &&
        this.$path('item.data.signableFrom') &&
        moment().isSameOrAfter(moment(this.$path('item.data.signableFrom')))

      return {
        isSignable,
        isExpired,
      }
    },
    signRefs: {
      get() {
        return this.categories
      },
      set(val) {
        return this.$emit('update:categories', val)
      },
    },

    isValid() {
      return this.signRefs.length
    },
  },
  methods: {
    ...mapActions('Contract', {
      fetch(dispatch) {
        this.isLoading = true
        return dispatch('fetch', [
          { id: this.$route.params.id, accessToken: this.$route.query.accessToken },
          { query: 'ShopContract', setPromise: false, resetBeforeAction: false },
        ]).then(() => (this.isLoading = false))
      },
    }),
  },
  created() {
    this.fetch()
  },
  components: {
    Categories,
  },
}
</script>

<style lang="scss">
.shop-signer-summary {
  &__actions {
    margin-top: 1rem;

    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  h3 {
    line-height: 1;
  }
}
</style>
