import { getByPath } from '@/utils'
import moment from 'moment'

function getCategoryState(id, contract) {
  const toDate = v => (v ? new Date(v) : null)
  const shop = getByPath('reference.shop', contract)
  const category = shop.categories.find(category => category.id === id)
  const partial = (getByPath('data.partials', contract) || []).find(({ reference }) => reference === id) || {}
  const signableFrom = toDate(partial.signableFrom || getByPath('data.signableFrom', contract))
  const expiresAt = toDate(partial.expiresAt || contract.expiresAt)

  const isSignable = signableFrom && moment().isSameOrAfter(moment(signableFrom))
  const isExpired = expiresAt && moment().isSameOrAfter(moment(expiresAt))

  const getStatus = () => {
    if (contract.status === 'draft') return 'draft'
    if (['signed', 'accepted'].some(s => [contract.status, category.status].includes(s))) return 'signed'
    if (isExpired) return 'expired'
    if (isSignable) return 'signable'
    if (!isSignable && signableFrom) return 'upcoming'
    return 'draft'
  }

  return {
    id: category.id,
    label: category.label,
    isSignable,
    isExpired,
    status: getStatus(),
    signableFrom,
    expiresAt,
  }
}

export { getCategoryState }
