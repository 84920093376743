<template>
  <Card class="shop-signer-confirmation" theme="border">
    <template #header>
      <h1>{{ $t('shopConfirmation') }}</h1>
    </template>
    <template>
      <Icon :icon="['fad', 'check-circle']" size="2x" />
      <div class="shop-signer-confirmation__message">{{ $t('shopSignedMessage') }}</div>
      <ButtonComponent :label="$t('goBack')" theme="primary" :icon="['fal', 'angle-right']" @click="$router.go()" />
    </template>
  </Card>
</template>

<script>
import { i18n } from '@/i18n'
import { mapState } from 'vuex'
import Card from '@/components/Card'

const meta = {
  order: 4,
  id: 'confirmation',
  label: i18n.tc('shopConfirmation'),
  icon: ['fal', 'file-alt'],
}

export { meta }
export default {
  props: {
    value: Object,
    labels: Object,
    validator: Object,
  },
  computed: {
    ...mapState('Contract', ['item']),
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.shop-signer-confirmation {
  text-align: center;
  border: none;

  .card__header {
    min-height: 125px;
  }

  &__message {
    max-width: 600px;
    line-height: 1.5;
    margin: 1rem auto 2rem;
  }

  h1 {
    margin: 0;
  }
}
</style>
